import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import CallToAction from "../../components/convincetoaction"

import blob from "../../assets/images/blogbob.svg"
import contactbg from "../../assets/images/webb.png"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Button from "@material-ui/core/Button"

import {
  FaLightbulb,
  FaBolt,
  FaGem,
  FaWrench,
  FaHandHoldingUsd,
  FaHandPointUp,
  FaBloggerB,
  FaCameraRetro,
  FaGlobe,
  FaCommentDots,
  FaStore,
  FaHome,
  FaBlog,
  FaArchway,
  FaPhotoVideo,
  FaTicketAlt,
  FaChartArea,
  FaTools,
  FaArrowRight,
} from "react-icons/fa"

const primary = "#26a27b"
const dark = "#3a866e"

const Background = styled.div`
  background-image: url(${blob});
  background-size: auto;
  background-repeat: no-repeat;
  border-bottom: 2px solid ${primary};
  background-position: 0% 0%;
  color: white;
  padding: 4rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 50%;
  }
`
const BoxStyling = styled.div`
  border-radius: 0.5rem;
  height: 15em;
  padding: 1.5rem;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  border-color: #f4f5f7;
  transition-duration: 0.3s;
  text-align: center;
  border-top: 2px solid ${(props) => props.theme.primary};
  &:hover {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  p {
    color: black;
  }
`

const useStyles = makeStyles((theme) => ({
  sectionCtr: {
    paddingBottom: "4em",
  },
  pageTitle: {
    color: `${dark}`,
    fontWeight: 500,
    fontSize: "1.75rem",
    lineHeight: "2.25rem",
    paddingTop: "2em",
    paddingBottom: ".15em",
  },
  subSectionHeader: {
    fontSize: "1.5rem",
    color: "#666666",
    fontWeight: 500,
  },
  subSectionBody: {
    color: "#616161",
  },
  servicesHeader: {
    fontSize: "1.5rem",
    color: "#666666",
    fontWeight: 500,
    marginBottom: "1em",
  },
  useGridItem: {
    padding: ".35em",
  },
  useItem: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  useIcon: {
    color: "#26a27b",
    marginRight: 5,
    marginTop: "-5px",
  },
  stateDescription: {
    paddingTop: ".75em",
    "& p": {
      color: "#616161",
    },
  },
  contact: {
    padding: 0,
  },
  contactImage: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  infoBackground: {
    position: "relative",
    backgroundImage: `url(${contactbg})`,
    boxShadow: `inset 2000px 0 0 0 rgba(38, 162, 123, 0.78)`,
    borderColor: `rgba(0, 0, 0, 0.5)`,
    backgroundPosition: "center",
    // backgroundSize: "cover",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100vw",
  },
  benefitsBtn: {
    marginTop: "2rem",
    backgroundColor: primary,
    color: "#FFF",
    "&:hover": {
      backgroundColor: dark,
      color: "#fff",
    },
  },
}))

let Box = ({ children, ...opts }) => (
  <BoxStyling {...opts}>{children}</BoxStyling>
)

let Service = ({ title, description, Icon = FaGem, nav = "/benefits" }) => (
  <Grid item xs={12} sm={4} style={{ marginBottom: "1em" }}>
    <Link
      to={nav}
      style={{ textDecoration: "none" }}
      title={`Link to benefits page`}
    >
      <Box>
        <Icon size={30} alt={`${title} icon`} />
        <Typography variant="h6" gutterBottom style={{ marginTop: "1em" }}>
          {title}
        </Typography>
        <Typography variant="body1" align="center">
          {description}
        </Typography>
      </Box>
    </Link>
  </Grid>
)

export default function StateServiceAreaPage({ data }) {
  const {
    state,
    fullLocation,
    // zipCodes,
    relatedCompanies,
  } = data.contentfulStateServiceArea
  const body =
    data.contentfulStateServiceArea.stateDescription.childMarkdownRemark.html
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))

  const seoTitles = [
    `Small business web design and development in ${fullLocation} by Snappy Web Design. `,
    `See why companies choose Snappy Web Design Website Development in ${fullLocation}. `,
    `Learn about our expert web design & development services in ${fullLocation} by Snappy Web Design. `,
  ]
  var item = seoTitles[Math.floor(Math.random() * seoTitles.length)]

  return (
    <Layout>
      <Seo
        noTitlePrefix={true}
        title={`${state} Website Design & Development Services | Snappy Web Design`}
        description={`Small Business Web Design in ${state}. Get a free estimate on your next project from Snappy Web Design`}
        location={state}
        relatedCompanies={relatedCompanies}
      />
      <Background>
        <h1
          style={{
            color: `${primary}`,
          }}
        >
          {state}
          <br />
          Web Design
        </h1>
      </Background>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ marginTop: ".75em", marginLeft: "1em", fontSize: ".8rem" }}
      >
        <Link color="inherit" component={Link} to="/">
          Home
        </Link>
        <Link color="inherit" component={Link} to="/locations/">
          Locations
        </Link>
        <Typography color="textPrimary" style={{ fontSize: ".8rem" }}>
          {state}
        </Typography>
      </Breadcrumbs>
      <Container style={{ maxWidth: 720 }}>
        <Grid container justify="center" style={{ paddingTop: "2em" }}>
          <Grid item xs={12} className={classes.sectionCtr}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  align="center"
                  className={classes.pageTitle}
                >
                  Professional Website Design Company in <br />
                  {fullLocation}
                </Typography>
              </Grid>
              <Divider
                style={{
                  width: "70%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "rgba(38,162,123,0.3)",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.sectionCtr}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  gutterBottom
                  className={classes.subSectionHeader}
                >
                  Why Choose Snappy Web Design
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subSectionBody}>
                  {item}
                  In the new digital age of business, functional and beautiful
                  websites aren't a luxury, but a requirement. For most, the
                  steep prices of custom websites warrant them unrealistic.
                  Snappy Web Design is disrupting this reality by providing
                  fast, modern, aesthetic, and inexpensive websites to small
                  businesses owners, organizations, and creatives in the {
                    state
                  }{" "}
                  region.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.sectionCtr}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3" className={classes.servicesHeader}>
                  Our Services
                </Typography>
              </Grid>
              <Grid container justify="center" spacing={2}>
                <Service
                  title="SEO"
                  nav="/seo-services/"
                  Icon={FaBolt}
                  description={
                    "Our websites rank high on Google, because they're designed for Google."
                  }
                />
                <Service
                  nav="/websitedesign/"
                  title="Web Design"
                  description={
                    "Your brand is your story. Your website should be designed to tell it."
                  }
                />
                <Service
                  nav="/websitedevelopment/"
                  title="Development"
                  Icon={FaWrench}
                  description={
                    "Fit your website with functionality and insights to increase revenue."
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Button
                    to="/benefits/"
                    component={Link}
                    className={classes.benefitsBtn}
                  >
                    <FaLightbulb className="mr-1 mb-1" alt="lightbulb icon" />
                    View More Benefits
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.sectionCtr}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3" className={classes.servicesHeader}>
                  Use Cases
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction={matchesSM ? "column" : "row"}>
                  <Grid item sm={12} md={6}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: ".91rem",
                            marginLeft: 3,
                            fontWeight: "500",
                            color: "#30ad86",
                            textTransform: "uppercase",
                            paddingBottom: ".75em",
                          }}
                        >
                          By Category
                        </Typography>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaHandPointUp
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            E-Commerce
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaBloggerB className={classes.useIcon} size={20} />{" "}
                            Blogs
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaCameraRetro
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Content & Media
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaGlobe className={classes.useIcon} size={20} />{" "}
                            Nonprofit Organizations
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaCommentDots
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Discussion Boards
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaHandHoldingUsd
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Services
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaStore className={classes.useIcon} size={20} />{" "}
                            Restaurant
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={6}
                    style={{ paddingTop: matchesSM ? "1.75em" : undefined }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: ".91rem",
                            marginLeft: 3,
                            fontWeight: "500",
                            color: "#30ad86",
                            textTransform: "uppercase",
                            paddingBottom: ".75em",
                          }}
                        >
                          By Type of Site
                        </Typography>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaHome className={classes.useIcon} size={20} />{" "}
                            Company Homepage
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaBlog className={classes.useIcon} size={20} />{" "}
                            Company Blog
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaArchway className={classes.useIcon} size={20} />{" "}
                            Custom Landing Pages
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaPhotoVideo
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Professional Image Gallery
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaTicketAlt
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Event Site
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaTools className={classes.useIcon} size={20} />{" "}
                            Client Management Site
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaChartArea
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Data Visualization
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.sectionCtr}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subSectionBody}>
                  Ready to build a better web? The modern web has astounding
                  capabilities, and we're excited to help bring your website up
                  to speed. You won't believe how affordable it is to upgrade
                  your website by partnering with Snappy. Contact Snappy Web
                  Design, voted the #1 Web Design Company in {fullLocation}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.sectionCtr}>
            <CallToAction headline="Wait a sec..." />
          </Grid>
          <Grid item xs={12} className={classes.sectionCtr}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  gutterBottom
                  className={classes.subSectionHeader}
                >
                  Web Design in {fullLocation}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={classes.stateDescription}
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                ></div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" className={classes.contact}>
        <Grid
          container
          style={{ height: "40em" }}
          alignItems="center"
          direction="row"
          className={classes.infoBackground}
        >
          <Grid
            item
            container
            style={{
              textAlign: matchesXS ? "center" : "inherit",
            }}
            direction={matchesXS ? "column" : "row"}
          >
            <Grid
              item
              sm
              style={{ marginLeft: matchesXS ? 0 : matchesSM ? "2em" : "5em" }}
            >
              <Grid
                container
                style={{ marginBottom: matchesXS ? "10em" : 0 }}
                direction="column"
              >
                <Typography variant="h2" style={{ color: "white" }}>
                  About Us
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "white",
                    fontSize: "1.5rem",
                    paddingBottom: "1em",
                  }}
                >
                  Let's get personal.
                </Typography>
                <Grid item>
                  <Button
                    component={Link}
                    to="/about/"
                    variant="outlined"
                    style={{ color: "white", borderColor: "white" }}
                    className={classes.learnButton}
                  >
                    <span style={{ marginRight: 10 }}>Learn More</span>
                    <FaArrowRight width={10} height={10} fill="white" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm
              style={{
                marginRight: matchesXS ? 0 : matchesSM ? "2em" : "5em",
                textAlign: matchesXS ? "center" : "right",
              }}
            >
              <Grid container direction="column">
                <Typography variant="h2" style={{ color: "white" }}>
                  Contact Us
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "white",
                    fontSize: "1.5rem",
                    paddingBottom: "1em",
                  }}
                >
                  Say Hello!{" "}
                  <span role="img" aria-label="waving hand">
                    👋🏼
                  </span>
                </Typography>
                <Grid item>
                  <Button
                    component={Link}
                    to="/contact/"
                    variant="outlined"
                    style={{ color: "white", borderColor: "white" }}
                    className={classes.learnButton}
                  >
                    <span style={{ marginRight: 10 }}>Learn More</span>
                    <FaArrowRight width={10} height={10} fill="white" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export const stateQuery = graphql`
  query getSingleStateArea($url: String) {
    contentfulStateServiceArea(url: { eq: $url }) {
      state
      fullLocation
      zipCodes
      relatedCompanies
      stateDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
