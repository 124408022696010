import React from "react"
import styled from "styled-components"
import { Row, Card } from "reactstrap"
import { FaArrowRight, FaRegAddressCard } from "react-icons/fa"
import bg from "../assets/images/webb.png"
import Button from "./btn"
import Link from "./link"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Hidden from "@material-ui/core/Hidden"

let CTAContainer = styled.div`
  height: 60vh;
  @media only screen and (max-width: 600px) {
    height: 50vh;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
  /* background-image: url(${bg}); */
  background-attachment: fixed;
  background-repeat: repeat-x repeat-y;
  border-radius: 6px;
`
let BgOverlay = styled.div`
  height: 100vh;
  @media only screen and (max-width: 600px) {
    height: 50vh;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    -moz-box-shadow: inset 0 0 10px #ccc;
    -webkit-box-shadow: inset 0 0 10px #ccc;
    box-shadow: inset 0 0 10px #ccc;
    border-radius: 6px;
  }
  background: rgb(255, 255, 255, 0.4);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 27%,
    rgba(249, 251, 253, 0.2) 60%
  );
`

const ConvinceToAction = ({ bgimage = bg, headline = "Reading on?" }) => {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <CTAContainer style={{ backgroundImage: `url(${bgimage})` }}>
      <BgOverlay>
        <Card>
          <Row className="justify-content-center text-center">
            <h6
              style={{
                fontSize: "2.5rem",
                fontWeight: 500,
                color: "#26a27b",
                marginTop: matchesXS ? "20%" : "13%",
              }}
            >
              {headline}
            </h6>
          </Row>
          <Row className="justify-content-center text-center">
            <p className="font-weight-bold mt-3">
              Check out a full list of Snappy benefits
            </p>
          </Row>
          <Row className="justify-content-center text-center mt-2">
            <Link to="/benefits/">
              <Button>
                View Benefits{" "}
                <FaArrowRight
                  style={{ textAlign: "center", fontSize: "0.9rem" }}
                />
              </Button>
            </Link>
          </Row>
          <Hidden xsDown>
            <Row className="justify-content-center text-center">
              <p className="font-weight-bold pt-5 mt-5">or, get in touch</p>
            </Row>
            <Row className="justify-content-center text-center mt-2">
              <Link to="/contact/">
                <Button>
                  Contact Us{" "}
                  <FaRegAddressCard
                    style={{ textAlign: "start", fontSize: "0.9rem" }}
                  />
                </Button>
              </Link>
            </Row>
          </Hidden>
        </Card>
      </BgOverlay>
    </CTAContainer>
  )
}

export default ConvinceToAction
